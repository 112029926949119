
import { defineComponent, Ref, ref } from 'vue';

import { RouteRecord } from '@/router';
import { FileType, fileTypes } from '@/view-models/promote.vm';

export default defineComponent({
  setup() {
    function getFileName(type: FileType) {
      switch (type) {
        case 'consumer':
          return '推薦消費者 DM';
        case 'deliveryDriver':
          return '推薦外送員 DM';
        case 'boss':
          return '推薦店家 DM';
      }
    }

    function getFileImage(type: FileType) {
      switch (type) {
        case 'consumer':
          return './re-appicon.png';
        case 'deliveryDriver':
          return './delivery.png';
        case 'boss':
          return './reboss-appicon.png';
      }
    }

    return {
      RouteRecord,
      fileTypes,

      getFileName,
      getFileImage,
    };
  },
});
