import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-2 text-center text-lg" }
const _hoisted_2 = { class: "bg-gray-50 p-4" }
const _hoisted_3 = { class: "w-full h-full bg-gray-300 text-3xl text-white flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_image = _resolveComponent("van-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_van_row, { gutter: 8 }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileTypes, (fileType) => {
          return (_openBlock(), _createBlock(_component_van_col, {
            key: fileType,
            span: "12"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, {
                to: { name: _ctx.RouteRecord.DownloadDetails.name, params: { type: fileType } },
                class: "block bg-white mb-2 border"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getFileName(fileType)), 1),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_van_image, {
                      class: "w-full h-48",
                      fit: "contain",
                      src: _ctx.getFileImage(fileType)
                    }, {
                      loading: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_van_icon, { name: "photo" })
                        ])
                      ]),
                      _: 2
                    }, 1032, ["src"])
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}